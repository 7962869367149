// State
export const state = () => ({
	user: {},
	tempUser: {},
	loading: false
})

// Actions
export const actions = {
	async login({ commit }, userData) {
		commit('setLoading', true)
		const response = await this.$axios
			.post(`/users/login`, userData, {
				headers: {
					'Content-Type': 'Application/json',
				}
			})
			.then((response) => {
				const responseData = response.data
				responseData.token = response.headers.token

				// If it needs to OTP to Login
				if (response.data.status === true) {
					commit('setTempUser', responseData)
					commit('setLoading', false)
					this.$cookie.set('sub_token', responseData.token)
				} else {
					// If it doesn't need to OTP to Login
					this.$cookie.set('token', response.headers.token, {
						path: "/",
						maxAge: 60 * 60 * 24 - 60,
					})
					// Store User necessary Data to Cookies
					const userDataForCookiesStored = {
						id: responseData.id,
						username: responseData.username,
						first_name: responseData.first_name,
						last_name: responseData.last_name,
						middle_name: responseData.middle_name,
						native_name: responseData.middle_name
					}
					this.$cookie.set('user', userDataForCookiesStored, {
						path: "/",
						maxAge: 60 * 60 * 24 - 60,
					})
					this.$cookie.remove('sub_token')
					this.$router.push('/')
				}
				return responseData
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		commit('setLoading', false)
		return response

	},

	async verityOtp({ commit, state }, form) {
		commit('setLoading', true)
		const response = await this.$axios
			.post(`/users/verify-tfa-otp`, form, {
				headers: {
					Authorization: 'Bearer ' + this.$cookie.get('sub_token')
				}
			})
			.then((response) => {
				const responseData = response.data

				this.$cookie.set('token', response.headers.token, {
					path: "/",
					maxAge: 60 * 60 * 24 - 60,
				})
				// Store User necessary Data to Cookies
				const userDataForCookiesStored = {
					id: responseData.id,
					username: responseData.username,
					first_name: responseData.first_name,
					last_name: responseData.last_name,
					middle_name: responseData.middle_name,
					native_name: responseData.middle_name
				}
				this.$cookie.set('user', userDataForCookiesStored, {
					path: "/",
					maxAge: 60 * 60 * 24 - 60,
				})
				this.$cookie.remove('sub_token')
				this.$router.push('/')
				return true
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
		commit('setLoading', false)
		return response

	},


	async logout({ dispatch }) {
		try {
			await this.$axios.get('/users/session/logout');
			this.$cookie.remove('token')
			this.$toast.info('Logout, See ya!')
			this.$router.push({ path: '/login' })
		} catch (error) {
			this.$toast.error(error)
		}
	},
}

// Mutations
export const mutations = {
	setLogin(state, response) {
		state.user = response
	},
	setTempUser(state, response) {
		state.tempUser = response
	},
	setLoading(state, type) {
		state.loading = type
	}
}

// Getters
export const getters = {}
