export default function (context) {
	// converting local time to UTC time
	const utcTime = (date) => {
		if (date) {
			return context.$moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
		}
		return context.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
	}
	context.$axios.onRequest((config) => {
		const type = context.$cookie.get('_theme_type') // default, portal
		config.baseURL = context.app.$config.API_DOMAIN
		if ((type === 'default' && config.url !== '/master/language-string')) {
			config.baseURL += '/cms'
		}
		if (config.url === '/users/role-permissions' || (config.url === '/language-string' && config.method === 'get') || config.url === '/users/login' || config.url.includes('/public/language-string')) {
			config.baseURL = config.baseURL.replace('/cms', '')
		}


		if (config.headers.Authorization) {
			// This case is for Login with 2FA, because 2FA to verify the OTP with the Token from Login Page
			config.headers.common.Authorization = `Bearer ${config.headers.Authorization}`
		} else {
			config.headers.common.Authorization = `Bearer ${context.$cookie.get('token')}`
		}

		const params = { ...config.params } || {}
		// Check if the request have dates
		if (params.start_date) params.start_date = utcTime(params.start_date)
		if (params.end_date) params.end_date = utcTime(params.end_date)

		for (const [key, value] of Object.entries(params)) {
			if (value || value === false || value === 0) {
				config.params[key] = value;
			} else {
				delete config.params[key]
			}
		}

	})
	context.$axios.onError((error) => {
		context.$toast?.clear();
		if (error?.response && error?.response?.data?.message) {
			try {
				const languageStrings = context.store.getters['language-strings/localeLanguage']('en-US')
				if (Object.keys(languageStrings).length > 0) {
					const text = languageStrings.find(el => el.code === error.response.data.message)
					if (text) {
						context.$toast.error(text.text)
					} else {
						context.$toast.error(error.response.data.message)
					}
				} else {
					context.$toast.error(error.response.data.message)
				}
			} catch (err) { }
		} else if (error.message !== '') {
			context.$toast.error('Contact developer, Services are updating')

		}

		//   Once user Unauthorized then moved them to login page.
		if (error.response.status === 401) {
			context.$cookie.remove('token')
			context.$cookie.remove('user')
			context.$cookie.remove('_theme_type')
			context.app.router.push('/login')
		}

		return false
	})
}
