// State
export const state = () => ({
	loading: false,
	item: {},
	generalInquiry: {},
	whistleBlower: {}
})

// Actions
export const actions = {
	async gets({ commit }) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/contact-info`)
				.then((response) => {
					commit('setItem', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async update({ commit }, form) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.post(`/contact-info`, form)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async generalInquiry({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/general-inquiry`, { params })
				.then((response) => {
					commit('setGeneralInquiry', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async whistleBlower({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/whistle-blower`, { params })
				.then((response) => {
					commit('setWhistleBlower', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setItem(state, data) {
		state.item = data
	},
	setGeneralInquiry(state, data) {
		state.generalInquiry = data
	},
	setWhistleBlower(state, data) {
		state.whistleBlower = data
	}
}

// Getters
export const getters = {}