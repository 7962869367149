export const state = () => ({
	loading: false,
	permissionList: [],
	roleList: [],
	permissions: [],
})

export const actions = {
	async getPermissionsList({ commit }, params) {
		try {
			await this.$axios
				.get(`/roles/permissions`, { params })
				.then((response) => {
					commit('setPermissionList', response.data)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async createRoles({ commit }, form) {
		try {
			const response = await this.$axios
				.post(`/roles`, form)
				.then((response) => {
					return true;
				})
				.catch(() => {
					return false;
				})
			return response
		} catch (error) {
		}
	},
	async updateRoles({ commit }, form) {
		const id = form.id;
		delete form.id;
		try {
			const response = await this.$axios
				.put(`/roles/${id}`, form)
				.then((response) => {
					return true;
				})
				.catch(() => {
					return false;
				})
			return response
		} catch (error) {
		}
	},
	async getRoles({ commit }, params) {
		try {
			const response = await this.$axios
				.get(`/roles`, { params })
				.then((response) => {
					commit('setRole', response.data)
				})
				.catch(() => {
					return false;
				})
			return response
		} catch (error) {
		}
	},
	async permissions({ commit }, id) {
		try {
			await this.$axios
				.get(`/roles/permissions/${id}`)
				.then((response) => {
					commit('permissions', response.data)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
}

export const mutations = {
	setPermissionList(state, data) {
		state.permissionList = data.sort((a, b) => a.sequence - b.sequence)
	},
	setRole(state, data) {
		state.roleList = data
	},
	permissions(state, data) {
		state.permissions = data
	},
}

export const getters = {}