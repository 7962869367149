// State
export const state = () => ({
	loading: false,
	item: {}
})

// Actions
export const actions = {
	async gets({ commit }) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/about-us`)
				.then((response) => {
					commit('setItem', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async update({ commit }, form) {
		const data = { ...form }
		const formData = new FormData()
		for (const key in data) {
			if (key === 'title' || key === 'description') {
				formData.append(key, JSON.stringify(data[key]));
			} else {
				formData.append(key, data[key]);
			}
		}
		try {
			commit('loading', true)
			const response = await this.$axios
				.post(`/about-us`, formData)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setItem(state, data) {
		state.item = data
	}
}

// Getters
export const getters = {}