export const state = () => ({})

export const actions = {
	async deleteDepartment({ commit }, id) {
		try {
			const response = await this.$axios
				.delete(`/departments/${id}`)
				.then((response) => {
					return true
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
}

export const mutations = {}

export const getters = {}