export const state = () => ({
	operators: {
		total: 1,
		totalPages: 1,
		data: []
	},
	employees: {
		total: 1,
		totalPages: 1,
		data: []
	},
	operator: {},
	user: {},
	existingOperatorDetailId: null
})

export const actions = {
	async getOperators({ commit }, params) {
		try {
			await this.$axios
				.get(`/operators`, { params })
				.then((response) => {
					commit('setOperators', response.data)
				})
		} catch (error) {
			//
		}
	},
	async getOperator({ commit, state }, id) {
		try {
			// Check if the data is already existing, stop the process
			// if (Object.keys(state.operator).length) {
			// 	if (state.existingOperatorDetailId && state.existingOperatorDetailId === id) {
			// 		return;
			// 	}
			// }
			// commit('setExistingOperatorDetailId', id)

			await this.$axios
				.get(`/operators/${id}`)
				.then((response) => {
					commit('setOperator', response.data)
				})
		} catch (error) {

		}
	},
	async createOperator({ commit }, form) {
		try {
			const response = await this.$axios
				.post(`/operators`, form)
				.then((response) => {
					return true;
				})
			return response
		} catch (error) {
		}
	},
	async updateOperator({ commit }, { id, form }) {
		try {
			const response = await this.$axios
				.put(`/operators/${id}`, form)
				.then((response) => {
					return true;
				})
			return response
		} catch (error) {
		}
	},
	async updateStatus({ commit }, { id, status }) {
		try {
			const response = await this.$axios
				.put(`/operators/status/${id}`, { status })
				.then((response) => {
					return true;
				})
			return response
		} catch (error) {
		}
	},
	async getOperatorEmployees({ commit }, params) {
		try {
			await this.$axios
				.get(`/operators/employees`, { params })
				.then((response) => {
					commit('setOperatorEmployees', response.data)
				})
		} catch (error) {

		}
	},
	async getUserById({ commit }, id) {
		try {
			const response = await this.$axios
				.get(`/users/${id}`)
				.then((response) => {
					commit('setUser', response.data)
					return response.data
				})
				.catch(() => {
					return {}
				})
			return response
		} catch (error) {

		}
	},
	async createEmployees({ commit }, form) {
		try {
			const response = await this.$axios
				.post(`/operators/employees`, form)
				.then((response) => {
					return true;
				})
			return response
		} catch (error) {
		}
	},
	async updateEmployee({ commit }, { id, form }) {
		try {
			const response = await this.$axios
				.put(`/operators/employees/${id}`, form)
				.then((response) => {
					return true;
				})
			return response
		} catch (error) {
		}
	},
	async updateEmployeesStatus({ commit }, form) {
		try {
			const response = await this.$axios
				.put(`/operators/employees/status/${form.id}`, {
					operator_id: form.operator_id,
					is_active: form.status
				})
				.then((response) => {
					return true;
				})
			return response
		} catch (error) {
		}
	},
	async getOperatorEmployeeById({ commit }, { id, params }) {
		try {
			const response = await this.$axios
				.get(`/operators/employees/${id}`, { params })
				.then((response) => {
					return response.data;
				})
			return response
		} catch (error) {
		}
	},
	async createEmployeeBulk({ commit }, form) {
		try {
			const response = await this.$axios
				.post(`/operators/employees/upload`, form)
				.then((response) => {
					return true;
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			return false
		}
	},
	async deleteOperatorDocument({ commit }, id) {
		const response = await this.$axios.delete('/operators/document/' + id)
			.then((data) => {
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	}
}

export const mutations = {
	setOperators(state, data) {
		state.operators = data

		let images = [];
		function preload() {
			for (let i = 0; i < data.data.length; i++) {
				images[i] = new Image();
				images[i].src = data.data[i]?.logo_url;
			}
		}
		preload()
		images = undefined;

	},
	setUser(state, data) {
		state.user = data
	},
	setOperator(state, data) {
		state.operator = data
	},
	setOperatorEmployees(state, data) {
		state.employees = data
	},
	setExistingOperatorDetailId(state, data) {
		state.existingOperatorDetailId = data
	}
}

export const getters = {}