export const state = () => ({
	loading: false,
	userActivities: {},
	activityTypes: [],
})

export const actions = {
	async userActivities({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/user-activities`, { params })
				.then((response) => {
					commit('userActivities', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async activityTypes({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/user-activities/types`, { params })
				.then((response) => {
					commit('activityTypes', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
}

export const mutations = {
	loading(state, v) {
		state.loading = v
	},
	userActivities(state, data) {
		state.userActivities = data
	},
	activityTypes(state, data) {
		state.activityTypes = data
	},
}