export const state = () => ({
	rolePermissions: [],
})

export const actions = {
	async getRolePermissions({ commit }, params) {
		try {
			await this.$axios
				.get(`/users/role-permissions`, { params })
				.then((response) => {
					commit('setRolePermissions', response.data)
				})
		} catch (error) {
		}
	},
}

export const mutations = {
	setRolePermissions(state, data) {
		state.rolePermissions = data
	}
}

export const getters = {
	checkIfHasPermission: (state) => (code, permissionType) => {
		if (!state.rolePermissions.length) return false
		const findMatchPermission = state.rolePermissions.find(el => el.permission_code === code)
		if (!findMatchPermission) return false
		return findMatchPermission[permissionType]
	}
}