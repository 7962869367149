export const state = () => ({
	reports: {
		total: 0,
		totalPage: 0,
		data: []
	},
	operator: {},
	reportOperator: {
		total: 0,
		totalPage: 0,
		overall_total_deposit: 0,
		overall_total_withdrawal: 0,
		overall_total_ggr_e_casino: 0,
		overall_total_ggr_e_sports: 0,
		overall_actual_fee_e_casino: 0,
		overall_actual_fee_e_sports: 0,
		overall_payable_fee_e_casino: 0,
		overall_payable_fee_e_sports: 0,
		data: []
	},

})

export const actions = {
	async reports({ commit }, params) {
		try {
			const response = await this.$axios
				.get(`/reports`, { params })
				.then((response) => {
					commit('setReports', response.data)
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async reportOperatorDetail({ commit }, id) {
		try {
			const response = await this.$axios
				.get(`/reports/operator/${id}`)
				.then((response) => {
					commit('setOperator', response.data)
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async reportOperator({ commit }, form) {
		try {
			const params = JSON.parse(JSON.stringify(form))
			const operatorId = params.operator_id
			delete params.operator_id

			if (params.sort.includes('custom_month')) {
				params.sort = params.sort.replace('custom_month', 'month')
			}

			const response = await this.$axios
				.get(`/reports/${operatorId}`, { params })
				.then((response) => {
					commit('setReportOperator', response.data)
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateReportStatus({ commit }, { id, status }) {
		try {
			const response = await this.$axios
				.put(`/reports/status/${id}`, status)
				.then((response) => {
					return true
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateReportPayAmount({ commit }, { id, body }) {
		try {
			const response = await this.$axios
				.put(`/reports/fee/${id}`, body)
				.then((response) => {
					return true
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
}

export const mutations = {
	setReports(state, data) {
		state.reports = data
	},
	setOperator(state, data) {
		state.operator = data
	},
	setReportOperator(state, data) {
		state.reportOperator = data
	},
}

export const getters = {}