import Vue from "vue"
import VueTheMask from 'vue-the-mask'
// import myLanguageString from '../assets/language-string.json'
Vue.use(VueTheMask)
const currencyJs = require('currency.js')

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__global__) {
	Vue.__global__ = true
	Vue.mixin({
		layout(context) {
			// _theme_type = 'portal' || 'official' = default
			const themes = ['default', 'portal']
			const themeType = context.$cookie.get('_theme_type')
			if (themes.includes(themeType)) {
				return themeType
			}
			return themes[0]
		},
		computed: {
			languages() {
				return [
					{ code: 'en-US', name: 'English', flag: '/languages/uk.png' },
					{ code: 'lo-LA', name: 'Lao', flag: '/languages/lao.png' },
				]
			},
			language() {
				return this.$store.state.settings.language
			},
			systemLocale() {
				return this.$store.state.settings.language
			},
			translationJson() {
				return this.$store.getters['language-strings/localeLanguage'](
					this.systemLocale
				)
			},
			checkPermission() {
				return this.$store.getters['internal/role-permissions/checkIfHasPermission']
			},
			masterLanguageStrings() {
				return this.$store.state.internal.master.languagesStrings
			},
			existingLanguages() {
				return this.$store.state.internal.master.existingLanguages
			}
		},
		methods: {
			// Logout user
			logout() {
				if (confirm(('sureDelete'))) {
					this.$store.dispatch('auth/logout')
				}
			},

			goBack() {
				return this.$router.go(-1)
			},


			// Check URL
			isURL(string) {
				let url;
				try {
					url = new URL(string);
				} catch (_) {
					return false;
				}
				return url.protocol === "http:" || url.protocol === "https:";
			},
			// ? DD-MM-YYYY HH:MM:SS format
			reverseDate(date = "2020-01-01") {
				if (date?.split(" ").length > 1) {
					const filterDate = date.split(' ')[0]
					if (filterDate.split("-")[0].length === 4) {
						return filterDate.split("-").reverse().join("-") + ' ' + date.split(' ')[1];
					}
					return date;
				} else if (date?.split("-")[0].length === 4) {
					return date.split("-").reverse().join("-");
				} else {
					return date;
				}
			},
			// ? YYYY-MM-DD HH:MM:SS format
			reverseDateBack(date = "2020-01-01") {
				if (!date) return "";
				if (date?.split(" ").length > 1) {
					const filterDate = date.split(' ')[0]
					if (filterDate.split("-")[0].length === 2) {
						return filterDate.split("-").reverse().join("-") + ' ' + date.split(' ')[1];
					}
					return date;
				} else if (date?.split("-")[0].length === 2) {
					return date.split("-").reverse().join("-");
				} else {
					return date;
				}
			},
			momentDate(date) {
				if (!date) return "";
				return this.$moment(date)
					.local()
					.format('DD-MM-YYYY HH:mm:ss')
			},
			// This function is used for display multiple languages
			// Since Eng is default one, sometimes we need this function to display the rest of languages
			removeFirstIndex(arr) {
				const newArr = [...arr]
				newArr.shift()
				return newArr
			},
			downloadPdfFile(url, fileName = 'file.pdf') {
				const link = document.createElement('a')
				link.href = url
				link.target = '_blank'
				link.download = fileName
				link.dispatchEvent(new MouseEvent('click'))
			},
			tl(code) {
				if (!this.masterLanguageStrings.length === 0) {
					return code;
				}
				const lang = this.masterLanguageStrings.find(el => el.code?.toLowerCase() === code.toLowerCase())
				let currentLang = 'en-US'
				// if (!lang && this.masterLanguageStrings.length > 0) {
				// 	if (!this.existingLanguages.includes(code)) {
				// 		const form =
				// 		{
				// 			"value": {
				// 				"en-US": code,
				// 				"lo-LA": code
				// 			},
				// 			"type": "both",
				// 			code
				// 		}
				// 		this.$store.dispatch(
				// 			'language-strings/addLanguageStrings',
				// 			form
				// 		)
				// 	}
				// 	this.$store.commit('internal/master/setExistingLanguages', code)
				// }
				if (this.systemLocale === 'lo-LA') {
					currentLang = 'lo-LA'
				}
				if (lang?.value && lang?.value[currentLang]) {
					return lang?.value[currentLang]
				}
				return `${code}`;
			},
			formatCurrencyJS(value, removeDecimal = false, precision = 2) {
				if (!value) value = 0
				const response = currencyJs(parseFloat(value), {
					separator: ',',
					symbol: '',
					precision
				}).format()
				if (removeDecimal) {
					// This precision is for exchange rates purposes only
					if (precision === 3)
						return response.replace('.000', '')
					return response.replace('.00', '')
				}
				return response
			},
			sumField(data, key) {
				try {
					if (!data) return null
					if (data?.data) {
						return data?.data?.reduce(
							(a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
							0
						)
					} else {
						return data?.reduce(
							(a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
							0
						)
					}
				} catch (error) {

				}
			},
		}
	}) // Set up your mixin then
}