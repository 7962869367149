// State
export const state = () => ({
	loading: false,
	categories: [],
	category: {}
})

// Actions
export const actions = {
	async categories({ commit }, data) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/article-category`, {
					params: data
				})
				.then((response) => {
					commit('setCategories', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async category({ commit }, id) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/article-category/${id}`)
				.then((response) => {
					commit('setCategory', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async deleteCategory({ commit, dispatch }, id) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.delete(`/article-category/${id}`)
				.then((response) => {
					commit('loading', false)
					dispatch('categories')
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async createCategory({ commit }, data) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.post(`/article-category`, data)
				.then(async (response) => {
					commit('loading', false)
					const resData = response.data
					const message = await this.dispatch(
						'language-strings/tl',
						resData?.message
					)
					this.$toast.success(message)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateCategory({ commit }, form) {
		const data = { ...form }
		const { id } = data
		delete data.id

		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/article-category/${id}`, data)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateSequence({ commit }, form) {
		const data = [...form]
		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/article-category/bulk-sequence`, data)
				.then(async (response) => {
					commit('loading', false)
					const resData = response.data
					const message = await this.dispatch(
						'language-strings/tl',
						resData?.message
					)
					this.$toast.success(message)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
}


// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setCategories(state, data) {
		state.categories = data
	},
	setCategory(state, data) {
		state.category = data
	}
}

// Getters
export const getters = {

}