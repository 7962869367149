// State
export const state = () => ({
	loading: false,
	articles: [],
	article: {}
})

// Actions
export const actions = {
	async articles({ commit }, data) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/article`, {
					params: data
				})
				.then((response) => {
					commit('setArticles', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async article({ commit }, id) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/article/${id}`)
				.then((response) => {
					commit('setArticle', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async deleteArticle({ commit, dispatch }, id) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.delete(`/article/${id}`)
				.then((response) => {
					commit('loading', false)
					dispatch('articles')
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async createArticle({ commit }, data) {
		try {
			const formData = new FormData()
			for (const key in data) {
				if (key === 'content' || key === 'description' || key === 'title' || key === 'link') {
					formData.append(key, JSON.stringify(data[key]));
				} else {
					formData.append(key, data[key]);
				}
			}
			commit('loading', true)
			const response = await this.$axios
				.post(`/article`, formData)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateArticle({ commit }, form) {
		const data = { ...form }
		const { id } = data
		delete data.id
		const formData = new FormData()
		for (const key in data) {
			if (key === 'content' || key === 'description' || key === 'title' || key === 'link') {
				formData.append(key, JSON.stringify(data[key]));
			} else {
				formData.append(key, data[key]);
			}
		}

		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/article/${id}`, formData)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateSequence({ commit }, form) {
		const data = [...form]
		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/article/bulk-sequence`, data)
				.then(async (response) => {
					commit('loading', false)
					const resData = response.data
					const message = await this.dispatch(
						'language-strings/tl',
						resData?.message
					)
					this.$toast.success(message)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
}


// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setArticles(state, data) {
		state.articles = data
	},
	setArticle(state, data) {
		state.article = data
	}
}

// Getters
export const getters = {

}