export const state = () => ({
	loading: false,
	departments: [],
	roles: [],
	countries: [],
	operators: [],
	languagesStrings: [],
	users: [],
	searchTokens: null,
	websiteList: [],
	companyList: [],
	employees: [],
	employeeTokens: null,
	// For temporary
	existingLanguages: []
})

export const actions = {
	async getDepartments({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/master/departments`, { params })
				.then((response) => {
					commit('setDepartment', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async getRoles({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/master/roles`, { params })
				.then((response) => {
					commit('setRoles', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async getCountries({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/master/countries`, { params })
				.then((response) => {
					commit('setCountries', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async getOperators({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/master/operators`, { params })
				.then((response) => {
					commit('setOperators', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async languagesStrings({ commit }, params) {
		try {
			await this.$axios
				.get(`/public/language-string`, { params })
				.then((response) => {
					commit('setLanguageStrings', response.data)
				})
		} catch (error) {
		}
	},
	async unAuthLanguagesStrings({ commit }, params) {
		try {
			await this.$axios
				.get(`/public/language-string`, { params })
				.then((response) => {
					commit('setLanguageStrings', response.data)
				})
		} catch (error) {
		}
	},
	async operators({ commit }, params) {
		try {
			await this.$axios
				.get(`/master/operators`, { params })
				.then((response) => {
					if (params.website_name) {
						commit('setWebsiteList', response.data)
					}
					if (params.company_name) {
						commit('setCompanyList', response.data)
					}
				})
		} catch (error) {
		}
	},
	async getUsers({ commit, state }, params) {
		if (state.searchTokens) {
			state.searchTokens.cancel('')
		}
		commit('setSearchToken', this.$axios.CancelToken.source())
		try {
			await this.$axios
				.get(`/master/users`, { params, cancelToken: state.searchTokens.token })
				.then((response) => {
					commit('setUsers', response.data)
				})
		} catch (error) {
		}
	},
	async getEmployees({ commit, state }, { id, params }) {
		if (state.employeeTokens) {
			state.employeeTokens.cancel('')
		}
		commit('setEmployeeToken', this.$axios.CancelToken.source())
		try {
			await this.$axios
				.get(`/master/employees/${id}`, { params, cancelToken: state.employeeTokens.token })
				.then((response) => {
					commit('setEmployees', response.data)
				})
		} catch (error) {
		}
	},
}

export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setDepartment(state, data) {
		state.departments = data.map((el) => {
			return {
				id: el.id,
				department_name: el.department_name
			}
		})
	},
	setRoles(state, data) {
		state.roles = data
	},
	setCountries(state, data) {
		state.countries = data
	},
	setOperators(state, data) {
		state.operators = data
	},
	setUsers(state, data) {
		state.users = data
	},
	setLanguageStrings(state, data) {
		state.languagesStrings = data.data
	},
	setSearchToken(state, data) {
		state.searchTokens = data
	},
	setEmployeeToken(state, data) {
		state.employeeTokens = data
	},
	setWebsiteList(state, data) {
		state.websiteList = data
	},
	setEmployees(state, data) {
		const list = data.map((el) => {
			return { ...el, full_name: `${el.first_name} ${el.last_name}` }
		})
		state.employees = list
	},
	setCompanyList(state, data) {
		state.companyList = data
	},
	setExistingLanguages(state, data) {
		if (!state.existingLanguages.includes(data)) {
			state.existingLanguages.push(data)
		}
	}
}

export const getters = {}