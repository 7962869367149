// State
export const state = () => ({
    loading: false,
    stats:{},
})


// Actions
export const actions = {
    // get all dashboard information
    async stats({ commit }, data) {
		commit('loading', true)
		await this.$axios
			.get(`/dashboard/stats`, {
				params: data
			})
			.then((response) => {
				commit('stats', response.data.data)
				commit('loading', false)
			})
	},
}


// Mutations
export const mutations = {
    loading(state, type) {
		state.loading = type
	},
	stats: (state, v) => {
		state.stats = v
	}
}

// Getters
export const getters = {}