export const state = () => ({
	loading: false,
	users: {
		total: 0,
		totalPage: 0,
		data: []
	},
	usersRoles: {
		total: 0,
		totalPage: 0,
		data: []
	},
	user: {},
	profile: {}
})

export const actions = {
	async getUsers({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/users`, { params })
				.then((response) => {
					commit('setUsers', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async getUsersRoles({ commit }, params) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/roles/users`, { params })
				.then((response) => {
					commit('setUsersRoles', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async getProfile({ commit }, params) {
		try {
			await this.$axios
				.get(`/users/profile`)
				.then((response) => {
					commit('setProfile', response.data)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async getUserById({ commit }, id) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/users/${id}`)
				.then((response) => {
					commit('setUser', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async createUser({ commit }, form) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.post(`/users`, form)
				.then((response) => {
					commit('loading', false)
					return true;
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateUser({ commit }, form) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/users/${form.id}`, form.form)
				.then((response) => {
					commit('loading', false)
					return true;
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateUserStatus({ commit }, { status, id }) {
		try {
			const response = await this.$axios
				.put(`/users/status/${id}`, { is_active: status })
				.then((response) => {
					return true;
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
		}
	},
	async createDepartment({ commit }, form) {
		try {
			const response = await this.$axios
				.post(`/departments`, form)
				.then((response) => {
					return true;
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			return false;
		}
	},
	async createUserBulk({ commit }, form) {
		try {
			const response = await this.$axios
				.post(`/users/upload`, form)
				.then((response) => {
					return true;
				})
				.catch(() => {
					return false
				})
			return response
		} catch (error) {
			return false;
		}
	},
	async resetTfa({ _ }, id) {
		const response = await this.$axios
			.put(`/roles/reset-tfa/${id}`)
			.then((_) => {
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async resetPassword({ _ }, { id, password }) {
		const response = await this.$axios
			.put(`/roles/reset-pass/${id}`, {
				password
			})
			.then((_) => {
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async resetUserPasswordByToken({ _ }, password) {
		const response = await this.$axios.put('/users/password', { password })
			.then((data) => {
				return true
			}).catch(_ => {
				return false
			})
		return response
	}
}

export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setUsers(state, data) {
		state.users = data
	},
	setUsersRoles(state, data) {
		state.usersRoles = data
	},
	setUser(state, data) {
		state.user = data
	},
	setProfile(state, data) {
		state.profile = data
	},
}

export const getters = {}