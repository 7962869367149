export const state = () => ({
	whitelists: [],
	tfaStatus: false,
})

export const actions = {
	async getWhitelists({ commit }, params) {
		try {
			await this.$axios
				.get(`/ip-whitelists`, { params })
				.then((response) => {
					commit('setWhitelists', response.data)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async createWhitelists({ commit }, form) {
		try {
			const createdResponse = await this.$axios
				.post(`/ip-whitelists`, form)
				.then((response) => {
					return true
				})
				.catch(() => {
					return false
				})
			return createdResponse
		} catch (error) {
		}
	},
	async update2FASetting({ commit }, status) {
		try {
			const updateResponse = await this.$axios
				.put(`/settings/tfa_setting`, {
					tfa_body: {
						is_active: status
					}
				})
				.then((response) => {
					commit('setTfaStatus', status)
					return true
				})
				.catch(() => {
					return false
				})
			return updateResponse
		} catch (error) {
		}
	},
	async getTfaSetting({ commit }, params) {
		try {
			await this.$axios
				.get(`/settings/code/tfa_setting`, { params })
				.then((response) => {
					commit('setTfaStatus', response?.data?.value?.is_active)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async deleteIpWhiteList({ commit }, id) {
		try {
			const response = await this.$axios
				.delete(`/ip-whitelists/${id}`)
				.then((response) => {
					return true
				})
			return response
		} catch (error) {
			return false
		}
	},
}

export const mutations = {
	setWhitelists(state, data) {
		state.whitelists = data.map(({ id, name, ip, created_at: createdAt, username }) => ({ name, ip, created_at: createdAt, username, id }));
	},
	setTfaStatus(state, data) {
		state.tfaStatus = data
	}
}

export const getters = {}