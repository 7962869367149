// State
export const state = () => ({
    loading: false,
    // jobCategories:{
    //     data: [
    //         {
    //             category_name: '',
    //         }
    //     ]
    // },
	// jobExperience:{
	// 	data: [
    //         {
    //             experience_name: '',
    //         }
    //     ]
	// },
	// jobType:{
	// 	data: [
    //         {
                
	// 			job_type_name: '',
				
    //         }
    //     ]
	// },
	jobs:{
		data: [
            {
                category_name: '',
				job_type_name: '',
				experience_name : '',
				job_title : '',
				job_description: '',
				job_short_description: '',
            }
        ]
	},
	JobsId: {},
	jobsApplied: {
		data:[]
	}
})

// Actions
export const actions = {
    // get all job categories
    // async jobCategories({ commit }, data) {
	// 	try {
	// 		commit('loading', true)
	// 		await this.$axios
	// 			.get(`/jobs/category`, {
	// 				params: data
	// 			})
	// 			.then((response) => {
	// 				commit('setJobcategories', response.data.data)
	// 				commit('loading', false)
	// 			})
	// 	} catch (error) {
	// 		this.$toast.error(error)
	// 		commit('loading', false)
	// 	}
	// },

    // Delete
	// async deleteJobcategory({ dispatch }, id) {
	// 	await this.$axios.delete(`/jobs/category/${id}`).then(() => {
	// 		this.$toast.success('job category deleted successfully')
	// 		dispatch('jobCategories')
	// 	}).catch((e) => this.$toast.error(e))
	// },
    // create job category
    // async createJobcategory({ commit }, data) {
    //     data.valid = undefined
	// 	commit('loading', true)
	// 	await this.$axios.post(`/jobs/category`, data).then(() => {
	// 		this.$toast.success("job category created successfully.")
	// 		commit('loading', false)
	// 		this.$router.push('/jobs/category')
	// 	}).catch(() => {
	// 		commit('loading', false)
	// 	})
	// },
    // Update job category
	// async updateJobcategory({ dispatch }, data) {
	// 	const id = data.id
    //     data.id = undefined
		
	// 	await this.$axios.put(`/jobs/category/${id}`, data ).then(() => {
	// 		this.$toast.success('job category updated successfully')
	// 		dispatch('jobCategories')
	// 	}).catch(() => false)
	// },
	// get all job experience
    // async jobExperience({ commit }, data) {
	// 	try {
	// 		commit('loading', true)
	// 		await this.$axios
	// 			.get(`/jobs/experience`, {
	// 				params: data
	// 			})
	// 			.then((response) => {
	// 				commit('setJobexperience', response.data.data)
	// 				commit('loading', false)
	// 			})
	// 	} catch (error) {
	// 		this.$toast.error(error)
	// 		commit('loading', false)
	// 	}
	// },
	// create job experience
    // async createJobexperience({ commit }, data) {
    //     data.valid = undefined
	// 	commit('loading', true)
	// 	await this.$axios.post(`/jobs/experience`, data).then(() => {
	// 		this.$toast.success("job experience level created successfully.")
	// 		commit('loading', false)
	// 		this.$router.push('/jobs/experience-level')
	// 	}).catch(() => {
	// 		commit('loading', false)
	// 	})
	// },
	// Update job experience level
	// async updateJobexperience({ dispatch }, data) {
	// 	const id = data.id
    //     data.id = undefined
		
	// 	await this.$axios.put(`/jobs/experience/${id}`, data ).then(() => {
	// 		this.$toast.success('job experience level updated successfully')
	// 		dispatch('jobExperience')
	// 	}).catch(() => false)
	// },
	// Delete job experience level
	// async deleteJobexperience({ dispatch }, id) {
	// 	await this.$axios.delete(`/jobs/experience/${id}`).then(() => {
	// 		this.$toast.success('job experience level deleted successfully')
	// 		dispatch('jobExperience')
	// 	}).catch((e) => this.$toast.error(e))
	// },
	// get all job type
    // async jobType({ commit }, data) {
	// 	try {
	// 		commit('loading', true)
	// 		await this.$axios
	// 			.get(`/jobs/type`, {
	// 				params: data
	// 			})
	// 			.then((response) => {
	// 				commit('setJobtype', response.data.data)
	// 				commit('loading', false)
	// 			})
	// 	} catch (error) {
	// 		this.$toast.error(error)
	// 		commit('loading', false)
	// 	}
	// },
	// create job type
    // async createJobtype({ commit }, data) {
    //     data.valid = undefined
	// 	commit('loading', true)
	// 	await this.$axios.post(`/jobs/type`, data).then(() => {
	// 		this.$toast.success("job type created successfully.")
	// 		commit('loading', false)
	// 		this.$router.push('/jobs/type')
	// 	}).catch(() => {
	// 		commit('loading', false)
	// 	})
	// },
	// Update job type
	// async updateJobtype({ dispatch }, data) {
	// 	const id = data.id
    //     data.id = undefined
		
	// 	await this.$axios.put(`/jobs/type/${id}`, data ).then(() => {
	// 		this.$toast.success('job type updated successfully')
	// 		dispatch('jobType')
	// 	}).catch(() => false)
	// },
	// Delete job type
	// async deleteJobtype({ dispatch }, id) {
	// 	await this.$axios.delete(`/jobs/type/${id}`).then(() => {
	// 		this.$toast.success('job type deleted successfully')
	// 		dispatch('jobType')
	// 	}).catch((e) => this.$toast.error(e))
	// },
	// get all jobs
    async jobs({ commit }, data) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/jobs`, {
					params: data
				})
				.then((response) => {
					commit('setJobs', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	},
	// create jobs
    async createJobs({ commit }, data) {
        data.valid = undefined
		commit('loading', true)
		await this.$axios.post(`/jobs`, data).then(() => {
			this.$toast.success("jobs created successfully.")
			commit('loading', false)
			this.$router.push('/jobs/jobs')
		}).catch(() => {
			commit('loading', false)
		})
	},
	// Delete jobs
	async deleteJobs({ dispatch }, id) {
		await this.$axios.delete(`/jobs/${id}`).then(() => {
			this.$toast.success('job deleted successfully')
			dispatch('jobs')
		}).catch((e) => this.$toast.error(e))
	},
	// Update jobs
	async updateJobs({ dispatch }, data) {
		const id = data.job_id
		data.job_id = undefined
		data.updated_at = undefined
		data.created_at = undefined
		// data.category_name = undefined
		// data.experience_name = undefined
		// data.job_type_name = undefined
		// console.log('hihihi:', data)
		await this.$axios.put(`/jobs/${id}`, data).then(() => {
			this.$toast.success('jobs updated successfully')
			dispatch('jobs')
			this.$router.push('/jobs/jobs')
		}).catch((e) => this.$toast.error(e))
	},
	// get job by ID
	async JobsId({ commit }, jobid) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/jobs/${jobid}`)
				.then((response) => {
					commit('setJobsId', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	},
	// get all job applied
	async jobsApplied({ commit }, data) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/job-applied`, {
					params: data
				})
				.then((response) => {
					commit('setJobsApplied', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			this.$toast.error(error)
			commit('loading', false)
		}
	},
}


// Mutations
export const mutations = {
    loading(state, type) {
		state.loading = type
	},
    setJobcategories(state, data) {
        state.jobCategories = data
    },
	setJobexperience(state, data) {
		state.jobExperience = data
	},
	setJobtype(state, data) {
		state.jobType = data
	},
	setJobs(state, data) {
		state.jobs = data
	},
	setJobsId(state, v) {
		state.JobsId = v
	},
	setJobsApplied( state, data) {
		state.jobsApplied = data
	}
}


// Getters
export const getters = {}