export const state = () => ({
	applications: {
		total: 0,
		totalPages: 0,
		page: 0,
		limit: 0,
		data: []
	},
	applicationChild: {},
	allCompanies: [],
	allMasterCompanies: [],
	licenseTypes: [
		{
			key: 1,
			value: 'E-sport betting',
		},
		{
			key: 2,
			value: 'E-casino',
		},
		{
			key: 3,
			value: 'Customer Relations Service Provider',
		},
		{
			key: 4,
			value: 'Strategic Support Provider',
		},
		{
			key: 5,
			value: 'IT Support Provider',
		},
		{
			key: 6,
			value: 'Gaming Software Platform Provider',
		},
		{
			key: 7,
			value: 'Live Studio and Streaming Provider',
		},
		{
			key: 8,
			value: 'Supporting Services Full License',
		},
	],
	cancelToken: {},
	uploadProgress: {}, // Stores progress percentages keyed by file ID
	uploadFilesCancelTokens: {},    // Stores cancel tokens keyed by file ID
	uploadProgressStatus: {},
	canceledFiles: {}
})

export const actions = {
	async getApplications({ commit }, payload) {
		const params = { ...payload }
		if (params.start_date && params.end_date) {
			const startDate = params.start_date.split(' ')[0]
			const endDate = params.end_date.split(' ')[0]
			params.start_date = `${startDate} 00:00:00`
			params.end_date = `${endDate} 23:59:59`
		}

		const response = await this.$axios
			.get(`/application-tracking`, { params })
			.then((response) => {
				if (params.parent_id) {
					commit('setApplicationChild', { key: params.parent_id, data: response?.data?.data })
				} else {
					// To Create a Company No. (Number NO.)
					const customNo = (v) => {
						return (params.page - 1) * params.limit + v + 1
					}
					const data = response.data
					const newData = data.data.map((el, index) => {
						return {
							...el,
							customNo: customNo(index)
						}
					})
					response.data.data = newData
					// Response the exactly page, and exactly limit to display the accurate data
					// in the listing Data Table
					commit('setApplications', {
						...response.data,
						page: params.page,
						limit: params.limit
					})
				}
				commit("setUploadProgressStatus", {})
				commit("clearUpload", {})
				commit("clearAllProgressState")
				return true
			})
			.catch((_) => {
				return false
			})
		return response
	},
	async searchAllCompanies({ commit, state }, params) {
		if (!params.company_name || params.company_name?.trim()?.length < 4) {
			return
		}
		if (state.cancelToken.searchAllCompanies) {
			state.cancelToken.searchAllCompanies.cancel('')
		}
		commit('setCancelToken', { searchAllCompanies: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/application-tracking/search`, { params, cancelToken: state.cancelToken.searchAllCompanies.token })
			.then((response) => {
				commit('setAllCompanies', response.data)
			})
			.catch((_) => {
				return false
			})
	},
	async searchAllMasterCompanies({ commit }, params) {
		await this.$axios
			.get(`/application-tracking/search-master`, { params })
			.then((response) => {
				const companies = response.data.sort((a, b) => {
					if (a.company_name < b.company_name) return -1;
					if (a.company_name > b.company_name) return 1;
					return 0;
				})
				commit('setAllMasterCompanies', companies)
			})
			.catch((_) => {
				return false
			})
	},
	async createApplicationTracking({ commit }, data) {
		const response = await this.$axios.post('/application-tracking', data)
			.then((data) => {
				return data.data
			})
			.catch((_) => {
				return false
			})
		return response
	},
	async onUpdateCompanyInfo({ commit }, { data, id }) {
		const response = await this.$axios.put(`/application-tracking/${id}`, data)
			.then((data) => {
				return data.data
			})
			.catch((_) => {
				return false
			})
		return response
	},
	expandAndCloseChildRows({ commit, state }, { companyId, isExpanding }) {
		const applications = JSON.parse(JSON.stringify(state.applications))
		const data = applications.data
		const getCompanyIndex = applications.data.findIndex(el => el.id === companyId)
		let newData = []
		if (!isExpanding) {
			// To clear out all the children list
			if (data[getCompanyIndex] && data[getCompanyIndex].children) {
				newData = JSON.parse(JSON.stringify(data[getCompanyIndex]))
				if (newData.children) {
					delete newData.children
				}
			}
			applications.data = data
			applications.data[getCompanyIndex] = newData
			commit('setForceApplicationsByExistingData', applications)
			return;
		}
		const findTheExpandingCompanyIndex = data.findIndex(el => el.id === companyId)
		if (findTheExpandingCompanyIndex < 0) return;

		const parentCustomNo = data[findTheExpandingCompanyIndex].customNo

		const getAllCompanyChild = state.applicationChild[companyId]

		if (getAllCompanyChild && getAllCompanyChild.length) {

			const formatData = getAllCompanyChild.map((el, index) => {
				return {
					...el,
					customNo: `${parentCustomNo}.${index + 1}`
				}
			})

			// Insert child applications into parent applications
			data[findTheExpandingCompanyIndex].children = formatData
		}
		applications.data = data
		commit('setApplications', applications)
	},
	async onDownloadFile({ commit }, { id, preview }) {
		const response = await this.$axios.get(`/application-tracking/download/${id}`, {
			params: {
				is_preview: preview
			}
		})
			.then((data) => {
				return data.data
			})
			.catch((_) => {
				return false
			})
		return response
	},
	async onDeleteFile({ commit }, id) {
		const response = await this.$axios.delete(`/application-tracking/delete/${id}`)
			.then((data) => {
				return true
			})
			.catch((_) => {
				return false
			})
		return response
	},
	async onUploadNewFile({ commit, state }, { applicationId, body, file }) {
		const { file_type: fileType } = body
		const fileId = file.name;

		// Check if the Files has already been canceled
		if (state.canceledFiles[fileId] === true) {
			return;
		}

		// Create a new cancel token for this file
		const CancelToken = this.$axios.CancelToken;
		const cancelSource = CancelToken.source();
		commit('setCancelUploadFilesCancelToken', { fileId, cancelToken: cancelSource });

		const response = await this.$axios.put(`/application-tracking/upload/${applicationId}`, body, {
			cancelToken: cancelSource.token,
		})
			.then(async (response) => {
				const requestId = response.headers['request-id'];

				const { url: uploadFileUrl } = response.data

				let contentTypeHeader = 'application/octet-stream'
				if (fileType) {
					const fileExtensionSplit = fileType.split('/')
					const fileExtension = fileExtensionSplit[1]
					// These files are for preview on the Browser
					if (fileExtension && ['png', 'jpg', 'jpeg', 'pdf'].includes(fileExtension?.toLowerCase())) {
						contentTypeHeader = `${fileType}`
					}
				}

				const uploadFileResponse = await this.$axios.put(uploadFileUrl, file, {
					headers: {
						'Content-Type': contentTypeHeader,
						'X-Goog-Content-Length-Range': `0,${file.size}`
					},
					cancelToken: cancelSource.token,
					onUploadProgress: (progressEvent) => {
						const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						commit('setUploadProgress', { fileId, progress });
					}
				})
					.then((data) => {
						return true
					})
					.catch((_) => {
						return false
					})
					.finally(() => {
						commit('clearUpload', fileId);
					})

				commit('setUploadProgressStatus', { fileId, status: uploadFileResponse ? 'success' : 'fail' })

				const uploadFileResponseStatus = await this.$axios.put(`/application-tracking/upload/status/${requestId ?? '72bfed1dc1b2'}`, { status: uploadFileResponse })
					.then((data) => {
						return true
					})
					.catch((_) => {
						return false
					})

				return uploadFileResponseStatus

			})
			.catch((_) => {
				return false
			})
		return response
	},
	cancelUpload({ state, commit }, fileId) {
		const cancelToken = state.uploadFilesCancelTokens[fileId];
		// To stop calling another APIS
		commit('setCancelFiles', { fileId, status: true })
		// To display cancel in the UploadProgress Dialog
		commit('setUploadProgressStatus', { fileId, status: 'fail' })

		if (cancelToken) {
			cancelToken.cancel(''); // Cancel the request
			commit('clearUpload', fileId); // Clear the state for this upload
		}
	}

}

export const mutations = {
	setApplications(state, data) {
		// Check if data is already existing then clone the Children component too
		const cloneApplications = JSON.parse(JSON.stringify(state.applications))
		if (data.data) {
			// Loop the new data to check if any new child,
			// If yes, then replace with the old one
			// If no, use the old one
			data.data.forEach((el, index) => {
				const existingApplication = cloneApplications.data.find(applicationEl => applicationEl.id === el.id)
				if (!el?.children?.length && existingApplication) {
					el.children = existingApplication.children || []
				}
			})
		}
		state.applications = data
	},
	setForceApplicationsByExistingData(state, data) {
		state.applications = data
	},
	setAllCompanies(state, data) {
		state.allCompanies = data
	},
	setAllMasterCompanies(state, data) {
		state.allMasterCompanies = data
	},
	setApplicationChild(state, { key, data }) {
		state.applicationChild[key] = data ?? []
	},
	setCancelToken: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	},
	setUploadProgress(state, { fileId, progress }) {
		state.uploadProgress = { ...state.uploadProgress, [fileId]: progress };
	},
	setCancelUploadFilesCancelToken(state, { fileId, cancelToken }) {
		state.uploadFilesCancelTokens = { ...state.uploadFilesCancelTokens, [fileId]: cancelToken };
	},
	setUploadProgressStatus(state, { fileId, status }) {
		state.uploadProgressStatus = { ...state.uploadProgressStatus, [fileId]: status };
	},
	setCancelFiles(state, { fileId, status }) {
		state.canceledFiles = { ...state.canceledFiles, [fileId]: status };
	},
	clearUpload(state, fileId) {
		if (!fileId) {
			state.uploadProgress = {}
			state.uploadFilesCancelTokens = {}
		} else {
			delete state.uploadProgress[fileId];
			delete state.uploadFilesCancelTokens[fileId];
		}

	},
	clearAllProgressState(state, _) {
		state.uploadProgress = {}
		state.uploadFilesCancelTokens = {}
		state.uploadProgressStatus = {}
		state.canceledFiles = {}
	}
}